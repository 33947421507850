import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "../components/Layout"

class Home extends React.Component {
  componentDidMount() {
    // Inactivity()
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <body className="home" />
        </Helmet>
        <main role="main">
          <section className="hero-area home-hero-area">
            <div className="home-hero-area__inner">
              <h1>
                <strong>Baby+Me Discovery Store</strong>
                <br />
                @ Schiphol Airport
              </h1>
              <p>Discover a never-seen-before immersive, educational experience for parents and babies.</p>
              <Link to="/event-experience" className="cta -white">Read more</Link>
            </div>
          </section>

          <section className="main-item">
            <i className="fas fa-plane"></i>
            <span className="main-item__title">
              Tips <strong>for Flying</strong>
            </span>
            <Link to="/tips-for-flying" className="main-item__link">Understand how you can make travelling with your little one less stressful.</Link>
          </section>

          <section className="menu-list">
            <Link to="/about-us" className="menu-button -white">About Us</Link>
            <Link to="/our-nutrition-pledge" className="menu-button -white">Our Nutrition Pledge</Link>
          </section>

        </main>
      </Layout>
    )
  }
}

export default Home